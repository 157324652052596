<template>
  <div
    :style="headerStyle"
    class="px-4 flex"
    :class="{
      'py-4 flex-col': !minimized,
      'py-2 items-center': minimized,
    }"
  >
    <!-- TODO: Hide BACK button from convo list > homepage if only Webchat channel is enabled in settings -->
    <svg
      v-if="showBackBtn"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      class="rounded-md bg-[rgba(255,255,255,0.3)] mr-4 hover:bg-[rgba(255,255,255,0.7)] transition-colors cursor-pointer"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="goBack"
    >
      <path
        d="M8.5002 12.8L14.2002 18.4C14.6002 18.8 15.2002 18.8 15.6002 18.4C16.0002 18 16.0002 17.4 15.6002 17L10.7002 12L15.6002 6.99999C16.0002 6.59999 16.0002 5.99999 15.6002 5.59999C15.4002 5.39999 15.2002 5.29999 14.9002 5.29999C14.6002 5.29999 14.4002 5.39999 14.2002 5.59999L8.5002 11.2C8.1002 11.7 8.1002 12.3 8.5002 12.8C8.5002 12.7 8.5002 12.7 8.5002 12.8Z"
        fill="#46494D"
      />
    </svg>

    <div
      class="flex gap-4 items-center"
      :class="{'items-center': minimized }"
    >
      <img
        v-if="widgetSettings.logo && businessLogo"
        class="transition duration-400 origin-top-right"
        :class="minimized ? 'w-[50px]' : 'w-[70px]'"
        :src="businessLogo"
        :alt="widgetSettings.businessName"
      />
      <div>
        <h3
          v-if="widgetSettings.businessName"
          class="text-xl transition-all"
          :style="`color: ${widgetSettings.headerTextColor};`"
        >
          {{ widgetSettings.businessName }}
        </h3>
        <p
          v-if="widgetSettings.tagline"
          class="transition-all"
          :class="{ 'h-0 overflow-hidden': minimized }"
          :style="`color: ${widgetSettings.headerTextColor};`"
        >
          {{ widgetSettings.tagline }}
        </p>
      </div>
    </div>

    <div
      class="absolute top-[10px] right-[10px] bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.6)] p-2 cursor-pointer rounded-md transition-colors hidden md:flex"
      @click="toggleExpanded"
    >
      <svg
        v-if="!expanded"
        class="expand"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      ><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          fill="#ffffff"
          d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
        />
      </svg>
      <svg
        v-else
        class="contract"
        xmlns="http://www.w3.org/2000/svg"
        height="1em"
        viewBox="0 0 448 512"
      ><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          fill="#ffffff"
          d="M160 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V64zM32 320c-17.7 0-32 14.3-32 32s14.3 32 32 32H96v64c0 17.7 14.3 32 32 32s32-14.3 32-32V352c0-17.7-14.3-32-32-32H32zM352 64c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V64zM320 320c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32s32-14.3 32-32V384h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320z"
        />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const store = usePortalStore()
const widgetSettings = computed(() => store.getWidgetSettings)
const defaultSettings = computed(() => store.getDefaultSettings)

const expanded = ref(false)

const showBackBtn = computed(() => {
  if (!minimized.value) {
    return false
  }

  if (
    widgetSettings.value.channels.length === 1 &&
    widgetSettings.value.channels.includes('webchat')
  ) {
    if (
      route.name.includes('conversations')
    ) {
      return false
    }

    if (
      route.name.includes('chat') &&
      (
        !store.getConversations ||
        store.getConversations.length === 0
      )
    ) {
      return false
    }
  }

  return true
})

const goBack = () => {
  if (
    route.name.includes('payments') ||
    route.name.includes('conversations')
  ) {
    goHome()
  }
  if (
    route.name.includes('chat')
  ) {
    goChats()
  }
  if (
    route.name.includes('invoice')
  ) {
    goPayments()
  }
}

const goHome = async () => {
  await router.replace(`/widget/${route.params.businessCode}/${route.params.group}`)
}

const goChats = async () => {
  if (store.getConversations?.length > 0) {
    await router.replace(`/widget/conversations/${route.params.businessCode}/${route.params.group}`)
  } else {
    await goHome()
  }
  store.SET_CONVERSATION()
}

const goPayments = () => {
  store.SET_INVOICE_CODE()
  router.replace(`/widget/payments/${route.params.businessCode}/${route.params.group}`)
}

const toggleExpanded = () => {
  expanded.value = !expanded.value
  window.parent.postMessage({
    type: 'set_expanded',
    value: expanded.value
  }, '*')
}

const minimized = computed(() => {
  return route.name.includes('chat') ||
    route.name.includes('conversations') ||
    route.name.includes('invoice') ||
    route.name.includes('payments')
})

const business = computed(() => {
  return store.business
})

const businessLogo = computed(() => {
  if (business.value?.logo) {
    return `${business.value.logo}?cb=${business.value.updated}`
  } else {
    return
  }
})

const headerStyle = computed(() => {

  return [
    `background-color: ${widgetSettings.value?.backgroundColour || defaultSettings.value?.backgroundColour};`,
    `color: ${widgetSettings.value?.headerTextColor || defaultSettings.value?.headerTextColor};`
  ].join('')
})
</script>
